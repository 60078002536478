import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { GetAllVehicleMakeAndModelsRawResponse, PartialVehicleModel } from "@/redux/apis/vehicleGuest/types";
import { useGetAllVehicleMakeAndModelsQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { capitalize } from "@/utils";

export interface IVehicleCategoryOffloadModels {
  activeMake?: string;
  setActiveMake: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters: {
    class: string[];
    types: string[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      class: string[];
      types: string[];
    }>
  >;
  options: {
    allMakes: GetAllVehicleMakeAndModelsRawResponse;
    allModels: PartialVehicleModel[];
    filteredModels: PartialVehicleModel[];
    classes: { value: string; name: string }[];
    types: { value: string; name: string }[];
  };
  isLoading: boolean;
}
export const VehicleCategoryOffloadModelsContext = createContext<IVehicleCategoryOffloadModels | null>(null);

export const VehicleCategoryOffloadModelsProvider = ({ children }: PropsWithChildren) => {
  const [activeMake, setActiveMake] = useState<string | undefined>(undefined);
  const { isFetching: isFetchingFilters, data: filterOptions } = useGetVehicleCategoriesFormOptionsQuery();

  const [classes, types] = useMemo(() => {
    if (!filterOptions) return [[], []];
    return [
      filterOptions.vehicleClass.map((vehicleClass) => ({
        value: vehicleClass,
        name: capitalize(vehicleClass.toLowerCase()),
      })),
      filterOptions.vehicleType.map((vehicleType) => ({
        value: vehicleType,
        name: getVehicleTypeLabel(vehicleType),
      })),
    ];
  }, [filterOptions]);

  const [filters, setFilters] = useState<{ class: string[]; types: string[] }>({
    class: classes.map((c) => c.value),
    types: types.map((t) => t.value),
  });

  const { isFetching, data } = useGetAllVehicleMakeAndModelsQuery(
    {
      class: filters.class.length > 0 ? filters.class : [],
      type: filters.types.length > 0 ? filters.types : [],
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const allModels: PartialVehicleModel[] = useMemo(() => {
    if (!data) return [];
    return Object.values(data)
      .map((make) => make.models)
      .flat();
  }, [data]);

  const filteredModels = useMemo(() => {
    if (!data || !activeMake) return [];
    return data[activeMake] ? data[activeMake].models : [];
  }, [activeMake, data]);

  return (
    <VehicleCategoryOffloadModelsContext.Provider
      value={{
        filters,
        setFilters,
        activeMake,
        setActiveMake,
        isLoading: isFetching || isFetchingFilters,
        options: {
          allMakes: data ?? {},
          allModels,
          filteredModels,
          classes,
          types,
        },
      }}
    >
      {children}
    </VehicleCategoryOffloadModelsContext.Provider>
  );
};

export const useVehicleCategoryOffloadModelsContext = () => {
  const context = useContext(VehicleCategoryOffloadModelsContext);
  if (!context) {
    throw new Error("useVehicleCategoryOffloadModelsContext must be used within an EditVehicleProvider");
  }
  return context;
};
