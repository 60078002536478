import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Panel, Typography } from "@/components/atoms";
import { PageList } from "@/components/molecules";
import { CancellationFees } from "./CancellationFees/CancellationFees";
import { FixedCostExtrasRoot } from "./FixedCostExtras/FixedCostExtrasRoot";
import { HourlyDailyHire } from "./HourlyDailyHire/HourlyDailyHire";
import { TimeAndDay } from "./TimeAndDay/TimeAndDay";
import { TransferTypesRoot } from "./TransferTypes/TransferTypesRoot";
import { TripCost } from "./TripCost/TripCost";
import { UnscheduledStops } from "./UnscheduledStops/UnscheduledStops";
import { VehicleCategoriesRoot } from "./VehicleCategories/VehicleCategoriesRoot";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: string;
  permission: [AppAction, AppSubject];
};

const subpages: Subpage[] = [
  {
    name: "Trip Cost",
    desc: "Standard trip pricing configuration.",
    to: "trip-cost",
    icon: "DollarCircle",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Transfer & Vehicle Options",
    desc: "Transfer & Vehicle Options configurations.",
    to: "vehicle-categories",
    icon: "Car",
    permission: ["manage", "providerConfigVehicle"],
  },
  {
    name: "Time & Day (Multiplier)",
    desc: "Configure cost multipliers depending on the time & day.",
    to: "time-and-day",
    icon: "Clock",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Hourly - Daily Hire",
    desc: "Hourly - Daily trip pricing configuration.",
    to: "hourly-daily-hire",
    icon: "Timer1",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Unscheduled Stops",
    desc: "Configure unscheduled stops.",
    to: "unscheduled-stops",
    icon: "Danger",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Cancellation Fees",
    desc: "Configure cancellation policies.",
    to: "cancellation-fees",
    icon: "MoneyRemove",
    permission: ["manage", "providerConfigPricing"],
  },
];

const getSubpages = (useNewVehicleManager: boolean) => {
  const legacyFixedCostExtrasPage: Subpage = {
    name: "Fixed Cost Extras",
    desc: "Pricing and configuration of fixed cost extras.",
    to: "fixed-cost-extras",
    icon: "EmptyWallet",
    permission: ["manage", "providerConfigPricing"],
  };

  const legacyVehicleCategoriesPage: Subpage = {
    name: "Vehicle Categories (Multiplier)",
    desc: "Vehicle categories configurations.",
    to: "vehicle-categories",
    icon: "Car",
    permission: ["manage", "providerConfigVehicle"],
  };

  const legacyTransferTypesPage: Subpage = {
    name: "Transfer Type (Multiplier)",
    desc: "Configure your transfer time options and multipliers.",
    to: "transfer-types",
    icon: "MoneySend",
    permission: ["manage", "providerConfigPricing"],
  };

  return useNewVehicleManager
    ? subpages
    : [
        ...subpages.slice(0, 1),
        legacyFixedCostExtrasPage,
        legacyVehicleCategoriesPage,
        ...subpages.slice(2, 3),
        legacyTransferTypesPage,
        ...subpages.slice(3),
      ];
};

export const Pricing = () => {
  const ability = useAppAbility();
  const canUseNewVehicleManager = ability.can("manage", "providerVehicleManager");

  return (
    <Panel>
      <Typography variant="h3">Pricing Setings</Typography>
      <PageList className="-mx-5 mt-2">
        {getSubpages(canUseNewVehicleManager).map(({ name, desc, to, icon, permission }) => (
          <PageList.Item
            key={name}
            title={name}
            description={desc}
            to={to}
            icon={icon}
            locked={!ability.can(permission[0], permission[1])}
          />
        ))}
      </PageList>
    </Panel>
  );
};

Pricing.TripCost = TripCost;
Pricing.FixedCostExtras = FixedCostExtrasRoot;
Pricing.VehicleCategories = VehicleCategoriesRoot;
Pricing.TimeAndDay = TimeAndDay;
Pricing.TransferTypes = TransferTypesRoot;
Pricing.CancellationFees = CancellationFees;
Pricing.UnscheduledStops = UnscheduledStops;
Pricing.HourlyDailyHire = HourlyDailyHire;
