import { forwardRef } from "react";
import { CheckboxInput, IconButton, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { unslugify } from "@/utils/unslugify";

interface VehicleMakesCheckboxInputProps extends React.HTMLAttributes<HTMLInputElement> {
  make: string;
  onCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isActive: boolean;
  isChecked: boolean;
}

export const VehicleMakesCheckboxInput = forwardRef<HTMLInputElement, VehicleMakesCheckboxInputProps>(
  ({ make, onCheckChange, onClick, isActive, isChecked }, ref) => {
    return (
      <div
        className={clsx("inline-flex justify-between rounded-sm p-1", {
          "bg-neutral-gray": isActive,
        })}
      >
        <CheckboxInput ref={ref} label="" value={make} checked={isChecked} onChange={onCheckChange} onClick={onClick} variant="check" />
        <Typography className="flex-1 cursor-pointer" onClick={onClick}>
          {unslugify(make)}
        </Typography>
        {isActive && (
          <IconButton
            iconName="ArrowRight2"
            size="sm"
            variant="tertiary"
            className="pointer-events-none -mr-2 py-0 text-neutral-black hover:!bg-transparent"
          />
        )}
      </div>
    );
  }
);

VehicleMakesCheckboxInput.displayName = "VehicleMakesCheckboxInput";
