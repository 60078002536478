import { isUATorStaging } from "@/helpers/urlHelpers";
import { FixedCostExtras } from "./FixedCostExtras";
import { FixedCostExtrasLegacy } from "./FixedCostExtrasLegacy";

export const FixedCostExtrasRoot = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <FixedCostExtras />;
  }

  return <FixedCostExtrasLegacy />;
};

const FixedCostExtrasRootAdd = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <FixedCostExtras.Add />;
  }

  return <FixedCostExtrasLegacy.Add />;
};

const FixedCostExtrasRootEdit = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <FixedCostExtras.Edit />;
  }

  return <FixedCostExtrasLegacy.Edit />;
};

FixedCostExtrasRoot.Add = FixedCostExtrasRootAdd;
FixedCostExtrasRoot.Edit = FixedCostExtrasRootEdit;
