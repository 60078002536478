import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  AddOnTextInput,
  Alert,
  Button,
  Icon,
  IconDropdown,
  RadioInput,
  Spinner,
  TextInput,
  Toggle,
  Tooltip,
  Typography,
} from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useEditCostExtraMutation } from "@/redux/apis/config/pricing/fixedCostExtras/fixedCostExtrasApi";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { addNotification, addToast, getCurrencySymbol } from "@/utils";
import { iconsOptions } from "../FixedCostExtras/fixtures";

interface EditFixedCostExtraFormProps {
  item?: CostExtrasItem;
}

export const EditFixedCostExtraForm = ({ item, ...props }: EditFixedCostExtraFormProps) => {
  const navigate = useNavigate();
  const { currency } = useOperator();
  const [editExtras, { isLoading }] = useEditCostExtraMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = useForm<CostExtrasItem>({
    defaultValues: {
      ...item,
      price: (item?.price || 0) / 100,
    },
  });

  const onSubmit = handleSubmit((data) => {
    editExtras({
      uuid: data.id,
      name: data.name,
      description: data.description,
      cost: data.price * 100,
      icon: data.icon,
      is_active: data.active,
      type: data.type,
    })
      .unwrap()
      .then(() => {
        addNotification("info", `Updated ${item?.name}`, `Successfully updated.`);
        navigate("../");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  });

  return (
    <form id="edit-extras-form" className="relative min-h-[515px] min-w-[515px] max-w-full space-y-5" onSubmit={onSubmit} {...props}>
      {isLoading && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90 pt-2">
          <Spinner className="h-8 w-8" />
        </div>
      )}
      <div className="mt-2">
        <div className="flex flex-row items-center gap-2">
          <Typography variant="action">Select a Category icon for the Fixed Cost Extra</Typography>
          <Tooltip content="This icon will be shown on the mobile app as a visual guide" placement="bottom" maxWidth={250}>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
        <Controller
          control={control}
          name="icon"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <>
              <IconDropdown
                options={iconsOptions}
                value={value}
                onChange={onChange}
                placeholder="Select a Category"
                hasError={!!formErrors.icon}
              />
              {formErrors.icon && <Alert className="mt-2 !py-2" type="danger" message="Please select an icon" />}
            </>
          )}
        />
      </div>
      <div>
        <Typography variant="action">Name</Typography>
        <TextInput {...register("name", { required: true })} placeholder="Enter Name" maxLength={128} hasError={!!formErrors.name} />
        {formErrors.name && <Alert className="mt-2 !py-2" type="danger" message="Please enter a name" />}
      </div>
      <div>
        <Typography variant="action">Description</Typography>
        <TextInput
          {...register("description", { required: true })}
          placeholder="Enter Description for the Cost Extras"
          maxLength={128}
          hasError={!!formErrors.description}
        />
        {formErrors.description && <Alert className="mt-2 !py-2" type="danger" message="Please enter a description" />}
      </div>
      <div>
        <Typography variant="action">Rate</Typography>
        <AddOnTextInput
          {...register("price", { required: true, valueAsNumber: true })}
          startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
          type="number"
          placeholder="0"
          step="0.01"
          min="-50000"
          max="50000"
          className="sm:max-w-[200px]"
          hasError={!!formErrors.price}
        />
        {formErrors.price && <Alert className="mt-2 !py-2" type="danger" message="Please enter a price" />}
      </div>
      <div>
        <div className="flex flex-row items-center gap-2">
          <Typography variant="action">Type</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Extra: An option selected by the passenger from a <br />
                drop down menu in the Booking Sheet (e.g. Baby Seat) <br />
                Fee: An administrative extra added by your <br /> internal staff (e.g. Booking Fee)
              </Typography>
            }
            placement="right"
          >
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
        <div className="mt-2 flex gap-6">
          <RadioInput {...register("type", { required: true })} label="Extra" value="extra" />
          <RadioInput {...register("type", { required: true })} label="Fee" value="fee" />
        </div>
      </div>
      <div>
        <Typography variant="action" className="block">
          Status
        </Typography>
        <div>
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <div className="mt-1 flex items-center gap-4">
                <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
                <Typography className="inline">{field.value ? "Active" : "Inactive"}</Typography>
              </div>
            )}
          />
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button
          variant="secondary"
          onClick={() => {
            navigate("../");
          }}
          className="px-8"
        >
          Cancel
        </Button>
        <Button variant="primary" disabled={!isDirty} type="submit" form="edit-extras-form" className="px-8">
          Update Fixed Cost Extra
        </Button>
      </div>
    </form>
  );
};
